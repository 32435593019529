@import url('https://fonts.googleapis.com/css2?family=Unbounded:wght@300;400;500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Merriweather+Sans:wght@300&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');

:root {
  --maroon: #a52a2a;
  --brand-primary: #f4bc35;
}

body {
  margin: 0;
  font-family: 'Poppins' !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.unbounded {
  font-family: 'Unbounded';
}

.brand-bg {
  background-color: var(--brand-primary) !important;
  border: none !important;
}

.brand-bg:hover {
  opacity: 0.9;
}


.brand-txt {
  color: var(--brand-primary) !important;
}

.hover-effect {
  transition: transform .5s;
}

.hover-effect:hover {
  /* opacity: 0.5; */
  transform: scale(1.2);
  transition: transform .5s;
}

